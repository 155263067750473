import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from './create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./main-menu-playboom')),
  playboom24: loadable(() => import('./main-menu-playboom')),
  freedomcasino: loadable(() => import('./main-menu-freedomcasino')),
  wettenlive: loadable(() => import('./main-menu-wettenlive')),
  casinosky: loadable(() => import('./main-menu-casinosky')),
  casinoskyjp: loadable(() => import('./main-menu-casinosky')),
  paradiseplay: loadable(() => import('./main-menu-paradiseplay')),
}

export const MainMenu = createBrandedComponent('MainMenu', BRANDED_COMPONENTS)
